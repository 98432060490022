import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export default function Navbar({ setContactUs, activeSection, setActivation }) {
  const [language, setLanguage] = useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang") === "en"
        ? "ar"
        : "en"
      : "ar"
  );
  // const [activeSection, setActivation] = useState(ac);
  const [isScrolling, setIsScrolling] = useState(false);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  const toggleDirection = (lng) => {
    const newDirection = lng === "ar" ? "rtl" : "ltr";

    document.documentElement.dir = newDirection;
    document.documentElement.lang = lng;
  };

  ///// useEffects

  // useEffect(() => {
  //   setActivation(activeSection);
  // }, [activeSection]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang") || "en");
    toggleDirection(
      localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (toggleNavbar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [toggleNavbar]);

  ///// handlers
  /// change language handler
  const onLanguageChange = (e) => {
    console.log(e.target.innerText);
    localStorage.setItem("lang", e.target.innerText);
    i18n.changeLanguage(e.target.innerText);
    setLanguage(e.target.innerText === "en" ? "ar" : "en");
    toggleDirection(e.target.innerText);
  };

  //////////

  return (
    <>
      <div
        className={`${
          isScrolling ? "shadow-md" : "shadow-none"
        } sticky top-0 bg-white z-[999]`}
      >
        <div className="container mx-auto py-2 px-2 sm:px-4  ">
          <div className="flex justify-between items-center">
            <img src="/images/orb-logo.png" className="h-[60px] md:h-[80px] " />
            {/* <div className="flex items-center"> */}
            <div className="hidden md:flex justify-center items-center  ">
              <a
                className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] 
             ${
               activeSection === "hero"
                 ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                 : ""
             }`}
                onClick={() => {
                  setActivation("hero");
                  window.scrollTo(0, 0);
                }}
              >
                {t("home")}
              </a>
              <a
                href="#strategy"
                className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                  activeSection === "strategy"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => setActivation("strategy")}
              >
                {t("work-strategy")}
              </a>
              <a
                href="#partner"
                className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                  activeSection === "partner"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => setActivation("partner")}
              >
                {t("partner")}
              </a>
              <a
                href="#download"
                className={`text-[14px] lg:text-[18px] mx-[6px] lg:mx-2 font-[600] hover:cursor-pointer hover:text-[#209163] py-2 hover:border-b-[2px] border-b-[#209163] ${
                  activeSection === "download"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => setActivation("download")}
              >
                {t("have-our-app")}
              </a>
            </div>
            {/* </div> */}
            <div className="flex items-center">
              <img src="/images/lang-icon.png" className="w-5" />
              {/* <select
              className="ltr: ml-1 ltr:mr-5 rtl:ml-5 rtl:mr-1 cursor-pointer"
              onChange={onLanguageChange}
              defaultValue={language}
            >
              <option value="en">En</option>
              <option value="ar">Ar</option>
            </select> */}
              <button
                className="font-[500] text-[18px] ltr: ml-1 ltr:mr-3 rtl:ml-3 rtl:mr-1 cursor-pointer text-black"
                onClick={onLanguageChange}
              >
                {language}
              </button>
              <button
                className=" border-none rounded-[8px] bg-[#209163] min-h-[35px] md:min-h-[40px] text-[14px] md:text-[16px] font-[400] md:font-[600] w-fit py-1 px-3 md:w-[140px] text-white"
                onClick={() => setContactUs(true)}
              >
                {t("contact-us")}
              </button>
              <img
                src="/images/nav-icon.png"
                className="w-[30px] md:w-[40px] object-contain md:hidden ms-2 md:ms-4 cursor-pointer"
                onClick={() => {
                  setToggleNavbar(!toggleNavbar);
                  console.log(activeSection);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {toggleNavbar && (
        <div className="fixed md:hidden top-0 left-0 bg-gray-600 text-white h-[100vh] w-[100vw] overflow-hidden z-[9999]">
          <div className="flex justify-between items-start p-12">
            <img src="/images/orb-logo.png" className="h-[80px] " />
            <p
              className="text-lg cursor-pointer font-semibold w-7 h-7 border-[1px] border-white rounded-[50%] flex justify-center items-center"
              onClick={() => setToggleNavbar(false)}
            >
              X
            </p>
          </div>
          <div className="flex justify-center items-center">
            <div className="p-4 md:hidden  flex flex-col">
              <a
                className={`mx-2 font-[600] w-fit hover:cursor-pointer p-3 hover:bg-[#209163] hover:text-white mt-2 md:mt-0 text-[18px] ${
                  activeSection === "hero"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => {
                  setActivation("hero");
                  setToggleNavbar(false);
                  window.scrollTo(0, 0);
                }}
              >
                {t("home")}
              </a>
              <a
                href="#strategy"
                className={`mx-2 font-[600] w-fit hover:cursor-pointer p-3 hover:bg-[#209163] hover:text-white mt-2 md:mt-0 text-[18px] ${
                  activeSection === "strategy"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => {
                  setActivation("strategy");
                  setToggleNavbar(false);
                }}
              >
                {t("work-strategy")}
              </a>
              <a
                href="#partner"
                className={`mx-2 font-[600] w-fit hover:cursor-pointer p-3 hover:bg-[#209163] hover:text-white mt-2 md:mt-0 text-[18px] ${
                  activeSection === "partner"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => {
                  setActivation("partner");
                  setToggleNavbar(false);
                }}
              >
                {t("partner")}
              </a>
              <a
                href="#download"
                className={`mx-2 font-[600] w-fit hover:cursor-pointer p-3 hover:bg-[#209163] hover:text-white mt-2 md:mt-0 text-[18px] ${
                  activeSection === "download"
                    ? "text-[#209163] border-b-[2px] border-b-[#209163]"
                    : ""
                }`}
                onClick={() => {
                  setActivation("download");
                  setToggleNavbar(false);
                }}
              >
                {t("have-our-app")}
              </a>
              <div className="flex justify-between items-center p-3">
                <div className="flex items-center">
                  <img src="/images/lang-white.png" className="w-7" />
                  <button
                    className="font-[500] text-[18px] ltr: ml-1 ltr:mr-5 rtl:ml-5 rtl:mr-1 cursor-pointer text-white"
                    onClick={onLanguageChange}
                  >
                    {language}
                  </button>
                </div>
                <button
                  className=" border-none rounded-[8px] bg-[#209163] min-h-[35px] md:min-h-[40px] text-[18px]  font-[400] md:font-[600] w-fit py-2 px-4 md:w-[140px] text-white"
                  onClick={() => setContactUs(true)}
                >
                  {t("contact-us")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
