import React from "react";
import { useTranslation } from "react-i18next";

export default function About({ setContactUs }) {
  const { t } = useTranslation();
  return (
    <div className="main container mx-auto py-[70px] px-4 sm:px-4 flex flex-col-reverse md:flex-row justify-between items-center" id="a">
      <div className="max-w-[500px] flex flex-col items-center md:flex-col md:items-start">
        <p className="text-[30px] font-[600] text-center md:text-left rtl:md:text-right"> {t("about")}</p>
        <p className="text-[#209163] text-[30px] font-[600] text-center md:text-left rtl:md:text-right">Smart Container</p>
        <p className="text-[20px] font-[400] mt-4 text-center md:text-left rtl:md:text-right">
          {t('about-smart-container')}
        </p>
        <button
          className="border-none rounded-[8px] bg-[#209163] h-[40px] font-[600] w-[120px] md:w-[150px] text-white mt-4"
          onClick={() => setContactUs(true)}
        >
          {t("contact-us")}
        </button>
      </div>

      <img src="/images/about-img.png" className="w-[300px] md:w-[400px]" />
    </div>
  );
}
