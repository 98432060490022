import React from "react";
import { useTranslation } from "react-i18next";

export default function DownLoadApp() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-[#209163] mt-[50px] scroll-mt-[120px]" id="download">
      <div className="container mx-auto mt-[70px] py-2 px-4 sm:px-4 flex flex-col-reverse md:flex-row justify-between items-center">
        <div className="max-w-[300px] md:max-w-[500px]">
          <p className="text-white text-[30px] md:text-[40px] font-[800] py-2">
            {t("download-the-application-now")}
          </p>
          <p className="py-2 text-[20px] font-[600]">
            {t('download-app-text')}
          </p>
          <div className="flex mt-4">
            <div className="bg-black flex p-2 items-center mr-3 rtl:ml-3 min-w-[130px] md:min-w-[150px] rounded-[4px]">
              <img
                src="/images/apple-img.png"
                className="w-[20px] sm:w-[25px] mx-2 object-contain"
              />
              <div className="text-white">
                <p className="text-[12px] font-[300] opacity-80">
                  {t('download-on-the')}
                </p>
                <p className="text-[14px] md:text-[18px] font-[400]">App Store</p>
              </div>
            </div>
            <div className="bg-black flex p-2 items-center ml-3 rtl:mr-3 min-w-[130px] md:min-w-[150px] rounded-[4px]">
              <img
                src="/images/googleplay-img.png"
                className="w-[20px] sm:w-[25px] mx-2 object-contain"
              />
              <div className="text-white">
                <p className="text-[12px] font-[300] opacity-80">{t('get-on-it')}</p>
                <p className="text-[14px] md:text-[18px] font-[400]">Google Play</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/images/mobile-img.png"
          className=" w-[250px] md:w-[300px]  object-contain"
        />
      </div>
    </div>
  );
}
