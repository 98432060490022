import React from "react";
import { useTranslation } from "react-i18next";

export default function Social() {
    const {t} = useTranslation()
  return (
    <div className="main bg-[#ECECEC] mt-[70px]" id="s">
      <div className="container mx-auto py-[50px] px-2 flex justify-center items-center">
        <div className="max-w-[250px] flex flex-col justify-center items-center">
            <img src="/images/orb-logo.png" className="w-[80px] my-4"/>
            <p className="mt-8 font-[500] text-[20px] text-center">{t("find-a-place-you-can-reuse-it")}</p>
            <div className="flex justify-center items-center p-2 my-4">
            <img src="/images/facebook.png" className="w-[20px] mx-2 cursor-pointer"/>
            <img src="/images/twitter.png" className="w-[20px] mx-2 cursor-pointer"/>
            <img src="/images/instagram.png" className="w-[20px] mx-2 cursor-pointer"/>
            <img src="/images/linkedin.png" className="w-[20px] mx-2 cursor-pointer"/>
            </div>
        </div>
      </div>
    </div>
  );
}
