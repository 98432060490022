import React from "react";
import { useTranslation } from "react-i18next";

export default function Strategy() {
  const { t } = useTranslation();
  return (
    <div
      id="strategy"
      className="mx-auto pt-[70px] px-2 flex flex-col justify-center items-center scroll-mt-[120px]"
    >
      <div className="max-w-[80%] flex flex-col justify-center items-center md:max-w-[50%] ">
        <p className="font-[600] text-[30px]">{t("work-strategy")}</p>
        <p className="text-[20px] font-[400] text-center">
          {t("find-a-place-you-can-reuse-it")}
        </p>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-4">
        <div className="bg-[#ECECEC] rounded-[8px] p-8 relative flex flex-col justify-start items-center w-[300px] md:w-[220px] lg:w-[300px] min-h-[300px] mt-[30px] md:mt-[10px] mx-0 md:mx-2 ">
          <img src="/images/bin.png" className="h-[80px] my-[15px]" />
          <p>{t("work-strategy-bin")}</p>
          <div className="bg-[#209163] rounded-[50%] absolute bottom-[-15px] w-[30px] h-[30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white">
            1
          </div>
        </div>
        <div className="bg-[#ECECEC] rounded-[8px] p-8 relative flex flex-col justify-start items-center w-[300px] md:w-[220px] lg:w-[300px] min-h-[300px] mt-[30px] md:mt-[10px] mx-0 md:mx-2 ">
          <img src="/images/arrows.png" className="h-[80px] my-[15px]" />
          <p>{t("examine-the-materials")}</p> 
          <div className="bg-[#209163] rounded-[50%] absolute bottom-[-15px] w-[30px] h-[30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white">
            2
          </div>
        </div>
        <div className="bg-[#ECECEC] rounded-[8px] p-8 relative flex flex-col justify-start items-center w-[300px] md:w-[220px] lg:w-[300px] min-h-[300px] mt-[30px] md:mt-[10px] mx-0 md:mx-2 ">
          <img src="/images/account.png" className="h-[80px] my-[15px]" />
          <p>{t("enter-the-bank-account")}</p>
          <div className="bg-[#209163] rounded-[50%] absolute bottom-[-15px] w-[30px] h-[30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white">
            3
          </div>
        </div>
      </div>
    </div>
  );
}
