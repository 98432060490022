import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="py-4 bg-black text-white" id="f">
      <div className="container mx-auto p-2 flex flex-col  md:flex-row justify-between items-center">
        <div className="flex justify-center items-center">
          <p className="mx-2 cursor-pointer hover:text-[#209163]">{t('privacy')}</p>
          <p className="mx-2 cursor-pointer hover:text-[#209163]">
            {t('terms-and-conditions')}
          </p>
          <p className="mx-2 cursor-pointer hover:text-[#209163]">{t('contact-us')}</p>
        </div>
        <div className="mt-[20px] md:mt-0"><p className="mx-auto text-center text-[14px] md:text-[16px]"> &copy; {t("copyright-apps-square-all-rights-reserved")}</p></div>
      </div>
    </div>
  );
}
