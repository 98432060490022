import React from "react";
import { useTranslation } from "react-i18next";

export default function Partner({ setSubscribe }) {
  const { t } = useTranslation();
  return (
    <div
      id="partner"
      className={`main bg-shapeA bg-center bg-contain bg-no-repeat mx-auto relative w-full min-h-fit md:min-h-[400px] flex justify-center items-center scroll-mt-[120px]`}
    >
      <div className="absolute bg-custom-gradient opacity-90 w-full h-full " />

      <div className={`container  px-2 z-[100]`}>
        <div className="my-[50px]  flex flex-col justify-center items-center z-[100] max-w-[80%] md:max-w-[50%] mx-auto">
          <p className="text-[30px] text-center font-[600] py-2">
            {t("partner")}
          </p>
          <p className="text-[20px] text-center font-[400] py-2">
            {" "}
            {t("partner-text")}
          </p>
          <button
            className=" border-none rounded-[8px] bg-[#209163] h-[40px] font-[600] w-[120px] md:w-[150px] text-white z-100 mt-2"
            onClick={() => setSubscribe(true)}
          >
            {t("subscribe")}
          </button>
        </div>
      </div>
    </div>
  );
}
