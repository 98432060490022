import React from "react";
import { useTranslation } from "react-i18next";

export default function Recycle() {
  const { t } = useTranslation();
  return (
    <div
    id="hero"
    className="main container mx-auto pt-[70px] py-2 px-4 sm:px-4 flex flex-col-reverse md:flex-row justify-between items-center" >
      <div className="max-w-[500px]">
        <p className="text-[#209163] text-center md:text-left rtl:md:text-right mt-[20px] md:mt-0 text-[30px] md:text-[40px] lg:text-[50px] font-[800] py-2">
          {t("recycling-plastic-material")}
        </p>
        <p className=" text-[20px] text-center md:text-left rtl:md:text-right md:text-[25px] lg:text-[30px] font-[600]">
          {t("find-a-place-you-can-reuse-it")}
        </p>
      </div>
      <img
        src="/images/recycle-img.png"
       className="w-[300px] md:w-[400px]"
      />
    </div>
  );
}
