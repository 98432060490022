import React from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function ContactModal({setContactUs}) {
  const {t}= useTranslation()
  return (
    <div className='w-[100vw] h-[100vh] fixed top-0 left-0 bg-black bg-opacity-90 z-[99999]' onClick={()=>setContactUs(false)}>
    

      <div className='w-[90%] p-4 sm:w-[500px] fixed min-h-[300px] top-[100px] bg-white left-1/2 transform -translate-x-1/2 z-[300] rounded-[10px]'
      onClick={(e)=>e.stopPropagation()}
      >
         <div className='w-full flex justify-between items-center relative'>
            <span className='absolute left-0 w-[30px] h-[30px] flex justify-center items-center rounded-[50%] bg-gray-200 cursor-pointer' onClick={()=>setContactUs(false)}>x</span>
            <p className='flex-1 text-center text-[28px] font-[600]'>{t('contact-us')}</p>
         </div>
         <form className='flex flex-col justify-center items-center w-[80%] md:w-[70%] mx-auto'>
            <input className='p-2 bg-gray-200 border-none rounded-md mt-3 w-full' placeholder={t('full-name')}/>
            <PhoneInput
            className=" bg-gray-200 border-none rounded-md mt-3 w-full"
            country={"sa"}
            //   value={this.state.phone}
            //   onChange={phone => this.setState({ phone })}
            placeholder={t("mobile-number")}
          />
            <input className='p-2 bg-gray-200 border-none rounded-md mt-3 w-full' placeholder={t('email-addess')}/>
            <textarea className='p-2 bg-gray-200 border-none rounded-md mt-3 w-full' placeholder={t('additional-notes')} minLength={4}/>
            <button className=" border-none rounded-[8px] bg-[#209163] h-[40px] font-[600] w-[120px] md:w-[150px] text-white z-100 mt-8">
       
          {t("subscribe")}
        </button>
         </form>
      </div>
      
    </div>
  )
}
