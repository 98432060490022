import React, { useState,useRef,useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Recycle from '../Components/Recycle'
import About from '../Components/About'
import Partner from '../Components/Partner'
import Strategy from '../Components/Strategy'
import DownLoadApp from '../Components/DownLoadApp'
import Social from '../Components/Social'
import Footer from '../Components/Footer'
import ContactModal from '../Components/ContactModal'
import SubscribeModal from '../Components/SubscribeModal'

export default function Home() {
  const [contactUs,setContactUs] = useState(false);
  const [subscribe,setSubscribe] = useState(false);
  // const sections = ['section1', 'section2', 'section3'];
  const [activeSection, setActiveSection] = useState('');
  // const sectionRefs = useRef({}); // Keep a reference to all the section elements
  const sectionRefs = useRef({});

  const sections = ["strategy", "partner", "download", "hero", "s", "f", "a"];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        console.log("entries", entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('entry',entry)
            console.log("id",entry.target.id)
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.8 }
    );

    sections.forEach((section) => {
      
      
      

      const sectionElement = document.getElementById(section);
      sectionRefs.current[section] = sectionElement;
      if (sectionElement) observer.observe(sectionElement);
      
    });

    return () => {
      sections.forEach((section) => {
        const sectionElement = sectionRefs.current[section];
        if (sectionElement) observer.unobserve(sectionElement);
      });
    };
  }, [sections]);

  
  return (
    <div>
        <Navbar setContactUs={setContactUs} activeSection={activeSection} setActivation={setActiveSection}/>
       <Recycle />
       <About setContactUs={setContactUs}/>
       <Partner setSubscribe={setSubscribe}/>
       <Strategy />
       <DownLoadApp />
       <Social />
       <Footer />
      
       {
contactUs&&
       <ContactModal setContactUs={setContactUs}/>
       }
       {
        subscribe && <SubscribeModal setSubscribe={setSubscribe}/>
       }
    </div>
  )
}
